@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/varelaround/v13/w8gdH283Tvk__Lua32TysjIfp8uP.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Lovelo';
    font-weight: 400;
    src: url('./assets/fonts/Lovelo.ttf') format('truetype');
  }
}

@layer components {
  .my-cursor {
    cursor: url('/src/assets/myCursor.png') 25 25, auto;
  }
  .my-cursor:active {
    cursor: url('/src/assets/myCursorActive.png') 25 25, auto;
  }
  .my-image {
    width: 90%;
    height: 90%;
  }
}
